<template>
    <div
        class="modal"
        :class="{ show: show, fade: fade }"
        ref="modalRef"
        :id="id"
        :data-bs-backdrop="required ? 'static' : null"
        :data-bs-keyboard="required ? 'false' : 'true'"
        tabindex="-1"
        aria-hidden="true"
    >
        <div class="container layout__container">
            <div class="row justify-content-center layout__row" @click="hide()">
                <div
                    class="align-self-end align-self-560-center layout__col"
                    :class="column"
                >
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <FormButton
                                v-if="close"
                                class="modal-close l-button--secondary l-button--icon-only"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                :size40="true"
                                @click="emit('hide', true)"
                            >
                                <IconsClose />
                            </FormButton>
                            <div class="modal-content-box" @click.stop="">
                                <div
                                    v-if="$slots.header"
                                    class="scale-1 scale-1--bold-uppercase modal-header"
                                >
                                    <slot name="header"></slot>
                                </div>
                                <div
                                    class="modal__scroll"
                                    v-if="$slots.default"
                                >
                                    <div
                                        class="equal-base modal-body"
                                        :class="{
                                            'modal-body--header': $slots.header,
                                        }"
                                    >
                                        <slot name="default">...</slot>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';

const props = defineProps({
    id: {
        type: String,
    },
    show: {
        type: Boolean,
        default: false,
    },
    column: {
        type: String,
        default: 'col-12',
    },
    required: {
        type: Boolean,
        default: false,
    },
    close: {
        type: Boolean,
        default: true,
    },
    fade: {
        type: Boolean,
        default: true,
    },
});

const modalRef = ref(null);
let modal = null;

onMounted(() => {
    if (process.server) return;
    modal = new window.__MODAL__(modalRef.value, { backdrop: true });
    modalRef.value.addEventListener('show.bs.modal', () => {
        emit('showing', true);
    });
    modalRef.value.addEventListener('shown.bs.modal', () => {
        emit('show', true);
    });

    if (props.show) {
        modal.show();
    }
});

function hide() {
    if (props.required) {
        return;
    }

    modal.hide();
    emit('hide', true);
}

const emit = defineEmits(['hide', 'show', 'showing']);
defineExpose({
    hide,
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
@import '@/node_modules/bootstrap/scss/mixins';

.modal {
    .layout__row {
        height: 100vh;
    }

    .modal-dialog {
        overflow-y: initial !important;
        max-height: 100vh;
        padding: 0;
        margin: 0;

        transform: unset !important;
        transition: none !important;

        @include media-breakpoint-down(560) {
            padding: 25px 15px 10px 15px;
        }

        .modal-content {
            background-color: unset;
            // max-height: calc(100vh - 35px);
            display: flex;
            border-width: 0;

            @include media-breakpoint-down(560) {
                flex-direction: column-reverse;
            }

            .modal-close {
                margin: 0 auto 10px auto;
                border: 1px solid $color-dark-01;
                background-color: $color-dark-02;
                &:hover,
                &:focus,
                &:active {
                    background-color: $color-dark-04;
                }

                :deep(.l-button__label) {
                    height: 50px;
                }

                @include media-breakpoint-up(560) {
                    &.l-button--size-40 {
                        width: 50px;
                        height: 50px;
                        font-size: 20px;
                    }
                }

                @include media-breakpoint-down(560) {
                    margin: 10px auto 0 auto;

                    :deep(.l-button__label) {
                        height: 40px;
                    }
                }
            }

            .modal-content-box {
                display: flex;
                flex-direction: column;
                padding: 10px;
                background-color: $color-dark-02;
                border-radius: 20px;
                max-height: calc(100vh - 60px);
                overflow: hidden;

                .modal-header {
                    border: 0;
                    padding: 20px 20px 0 20px;
                }

                .modal__scroll {
                    height: auto;
                    overflow-y: auto;
                    overflow-x: hidden;

                    /* scroll */
                    &::-webkit-scrollbar {
                        width: 2px;
                    }

                    &::-webkit-scrollbar-track {
                        border-radius: 1px;
                        background-color: #262626;
                    }

                    &::-webkit-scrollbar-thumb {
                        background: #333333;
                        border-radius: 10px;
                        border: 5px solid transparent;
                    }

                    &::-webkit-scrollbar-thumb:hover {
                        background: #333333;
                    }

                    .modal-body {
                        overflow-y: unset;
                        padding: 20px;

                        &--header {
                            padding-top: 20px;
                        }
                    }
                }
            }
        }
    }
}
</style>
